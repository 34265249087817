@import "../../../../theme/color/index.scss";
@import "../../../../theme/fonts/index.scss";
.main_profile{
   background-color: #212227;
   color: white;
   overflow-y: hidden;
  //  height: auto;
}
.link_Pages {
  position: relative;
  width: 320px;
  .nested_links_sidebar {
    position: fixed;
    top: 80px;
    left: 0;
    z-index: 9999;
  background: linear-gradient(164deg, #9d0208 0%, #212227 100%) 0% 0% no-repeat;
  opacity: 1;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  height: 100%;
  overflow-y: hidden;
  width: 280px;

  a{
  width: 100%;
  display: inline-flex;
  align-items: center;
  gap: 20px;
  font-size: $fontSizeForText;
  color: $headingColor;
  }
  .active{
    background: #9D0208 0% 0% no-repeat;
box-shadow: inset 0px 4px 5px #21222780;
opacity: 1;

  }
  .user_img{
    svg{
      width: 120px;
      height: 120px;
    //   border-radius: 50%;

    }
}
  }

}
