$themeColor: #9D0208;
$textColor: #fff;
$black: #000;
$boxShadow: inset 0px 0px 10px #E7E7E780, 0px 0px 6px #E7E7E780;
$boxshadowForInputs: inset 0px 0px 10px #9D02080D, 0px 0px 10px #EAEAEACC;
$linkColor: #EFEFEF;
$grey: #9799a0;
$fontColor: #9b9b9b;
$outlineWholePageSahdow: #212227;
$graphBackground: #121826;
$borderColorLight: #9D020880;
$cardColor: rgba(143,39,40,0.8);
$bgColorGradient: linear-gradient(179deg, #21222700 0%, #212227 100%);
$headingColor: #FBFBFB;
$landingPageColor: #1E1B18;
$borderColor: #E7E7E780;
$landingPageTextColor: #21222780;
$boxShadowBtn: inset 0px 4px 5px #21222780;;
$HeadingColor: #F5F5F5;
// $bgColorGradient: linear-gradient(
//   159deg,
//   rgba(0, 66, 96, 1) 0%,
//   rgba(7, 28, 59, 1) 28%,
//   rgba(9, 18, 49, 1) 52%,
//   rgba(51, 18, 61, 1) 69%,
//   rgba(96, 17, 74, 1) 100%
// );
// $headinColor: #383f51;
// $graphHeadingTextColor: #d1d1d1;
// $inputBtnColor: #06c7e8;
// $headingColorGreen: #2aff8c;
// $boxShadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

