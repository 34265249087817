@import "../../../theme/color/index.scss";
@import "../../../theme/fonts/index.scss";
.main_Header {
  position: absolute;
  z-index: 9999;
  // background-color: rgba(13, 13, 13, 0.4);
  .nested_header {
    width: 90%;
    margin: 0 auto;
    font-family: $fontFamily;
    color: $headingColor;

    .logo_Section {
      h1 {
        font-size: $fontSizeForHeading;
        font-weight: $fontWeightForText;
        color: $headingColor;
        font-family: $fontFamily;
        text-transform: uppercase;
      }
      a {
        // text-decoration: underline;
       @media (max-width:520px) {
        display: none;
       } 
      }
    }
    .header_Buttons {
      button {
        width: 180px;
        height: 64px;
        @media (max-width:600px) {
          width: 140px;
          // height: 48px;
        }
      }
      a {
        &:first-child {
          background-color: $themeColor;
          font-size: $fontSizeForText;
          box-shadow: $boxShadowBtn;
      
        }
        &:nth-child(2) {
          background-color: $headingColor;
          color: $graphBackground;
          font-size: $fontSizeForText;
            box-shadow: $boxShadowBtn;
            @media (max-width:768px) {
              display: none;
            }
        }
      }
    }
    @media (max-width:350px) {
      width: 100%;
      padding: 12px 10px;
    }
  }
}
