@import "../../../../theme/color/index.scss";
@import "../../../../theme/fonts/index.scss";
.opt_Section{
    height: 100vh;
    width: 100%;
    background-image: url("../../../../assets/images/main_bg.jpg");
    background-position: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: $fontFamily;
    .nested_otpSection{
        background: transparent $bgColorGradient no-repeat;
        height: 100vh;
        opacity: 1;
        width: 100%;
   .otp_FormConatiner{
        width:  650px;
        margin: 0 auto;
        background-color: $cardColor;
        color: white;
        height: 50%;
        backdrop-filter: blur(4px);
        box-shadow: $boxshadowForInputs;
        border-radius: $borderRadius;
        // max-height: 100vh;
        .heading_Otp{
            h1{
                font-size: 54px;
        font-weight: $fontWeightForText;
        font-family: $fontFamily;
        text-align: center;

        @media (max-width:425px) {
          font-size: 45px;
        }
            }
            

        }
        .otp_FormSection{
            input{
             width: 55px;
             padding: 12px 16px;
                // box-shadow: $boxshadowForInputs;
                border-radius: 10px;
                opacity: 1;
                backdrop-filter: blur(7px);
                -webkit-backdrop-filter: blur(7px);
                background-color: transparent;
                letter-spacing: 0.4px;
                font-size: $fontSizeForSubHeading;
                border: 2px solid white;
                &:focus {
                //   border: none;
                  outline: none;
                  border: 2px solid white;

                }
                &::placeholder {
                  color: #eaeaea;
                }
            }
            @media (max-width:550px) {
              input{
                width: 50px;
                height: 55px;
                
              }
            }
            @media (max-width:360px) {
              .input_Div{
                gap: 2px;

              }
              
            }
         }
         .otp_Btn{

            button {
                width: 240px;
                height: 64px;
                background-color: $headingColor;
                color: $landingPageColor;
                font-size: $fontSizeForSubHeading;
                box-shadow: $boxShadowBtn;
                transition: all 0.3s ease;
                cursor: pointer;
              }
              button:active {
                transform: scale(0.95);
              }
         }

         @media (max-width:768px) {
           width: 80%;
         }
         @media (max-width:768px) {
          width: 100%;
        }
    }
    }
}