@import "../../../../theme/color/index.scss";
@import "../../../../App.scss";

.ant-checkbox-inner {
  border-color: $borderColorLight !important;
  &:hover {
    border-color: $themeColor !important;
  }
}
.ant-checkbox-wrapper {
  color: $textColor !important;
  &:hover {
    border-color: $themeColor !important;
  }
}
.ant-checkbox-checked .ant-checkbox-inner {
  border-color: $borderColorLight !important ;
  background-color: black !important;
  &:hover {
    border-color: $themeColor !important;
  }
}
.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: $borderColorLight !important;
}
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: $themeColor !important;
}
s .ant-checkbox-inner,
.ant-checkbox-input {
  transform: scale(1) !important;
}

.ant-checkbox-checked::after {
  border: 1px solid $borderColorLight !important;
}
