@import "../../../../../theme/color/index.scss";
@import "../../../../../theme/fonts/index.scss";

.upload_movies_btn_section{
       width: 100%;
    .nested_upload_movies_btn_section{
          margin: 0 auto;
          font-family: $fontFamily;
        .movie_btn{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-height: 50vh;
            max-height: auto;
        }
        .text_section{
            color: #F5F5F5;
             opacity: 1;  
             text-align: center;
             font-family: $fontFamily;
           h3{
              font-size: $fontSizeForSubHeading;  
              font-weight: $fontWeightForText;
           }
           
        }
        .select_btn{
           button, input{
            background-color: $headingColor;
          color: $graphBackground;
          font-size: $fontSizeForText;
          border-radius: $borderRadius;
          padding: 12px 12px;
          //  width: 200px;
           box-shadow: $boxShadowBtn;
           margin: 12px auto;
           } 
        }
    }
    .next_section_btn {
      background: transparent linear-gradient(179deg, #9d0208 0%, #212227 100%)
        0% 0% no-repeat padding-box;
      // box-shadow: inset 0px 0px 10px #9d02080d, 0px 0px 10px #eaeaeacc;
      box-shadow: inset 0px 0px 20px #e7e7e7cc;

      border-radius: 4px;
      padding: 20px 32px;
      .next_btn_first_step {
        button {
          width: 220px;
          height: 64px;
          background-color: $headingColor;
          color: $graphBackground;
          font-size: $fontSizeForSubHeading;
          box-shadow: $boxShadowBtn;
          @media (max-width: 600px) {
            width: 140px;
            // height: 48px;
          }
        }
      }
    }
    .upload_section{
      display: flex;
      gap: 10px;
      width: 100%;
      .video_upload,.img_upload{
        width: 100%;
        min-height: auto;
        // .movie_btn{
        //   min-height: auto;
        // }
      }

    
    }
}
.loading{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: black;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    z-index: 999999999999999999999999999999999 !important;
    color: white;
}