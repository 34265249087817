@import "../../../theme/color/index.scss";
@import "../../../theme/fonts/index.scss";
.form_Header {
  position: relative;

  .nested_FormHeader {
    width: 100%;
    max-width: 100%;
    height: 70px;
    background-color: $themeColor;
    position: fixed;
    // inset: 0;
    z-index: 9999;

    .form_content {
      // width: 80%;
      // margin: 0 auto;
      color: $textColor;
      font-family: $fontFamily;
.logo_Section, .user_Details{

    h1 {
        font-size: $fontSizeForHeading;
        font-weight: $fontWeightForText;
        text-transform: uppercase;
      }
      p{
        font-weight: $fontWeightForText;
        font-size: $fontSizeForText;
      }
}
@media (max-width:450px) {
  width: 95%;
}

    }
  }
}
