@import "../../../theme/color/index.scss";
@import "../../../theme/fonts/index.scss";
.creator_header_section {
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: $themeColor;
  //    #9D0208
  .nested_Creator_header_section {
    width: 95%;
    height: 100%;
    margin: 0 auto;
    font-family: $fontFamily;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo_section {
      display: flex;
      align-items: center;
      gap: 18px;
      h1 {
        font-size: $fontSizeForHeading;
        font-weight: $fontWeightForText;
        color: $headingColor;
      }
      p {
        font-size: $fontSizeForText;
        font-weight: $fontWeightForText;
        color: $headingColor;
        margin-left: 38px;
      }
    }
    .search_Media {
      width: 40%;
      .child_search {
        width: 100%;
        // border: 1px solid gray;
        color: $headingColor;
        padding: 4px 10px;
        box-shadow: $boxShadow;
        border-radius: 10px;
        opacity: 1;
        background: transparent $bgColorGradient 0% 0% no-repeat;
        backdrop-filter: blur(7px);
        -webkit-backdrop-filter: blur(7px);
        font-family: $fontFamily;

        input {
          background-color: transparent;
          border: none;
          outline: none;
          width: 100%;
          padding-left: 12px;
          font-family: $fontFamily;

          &::placeholder{
            color: #EAEAEA;
          }
        }
        button {
          width: 60px;
          svg {
            font-size: $fontSizeForText;
          }
        }
       
      }
    }
    .creator_name{
        p{
            font-size: $fontSizeForText;
            font-weight: $fontWeightForText;
            color: $headingColor;
            margin-right: 14px;
        }
        svg{
            width: 32px;
            height: 32px;
            border-radius: 50%;
            object-fit: cover;
        }
    }
  }
}
