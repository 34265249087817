@import "../../../../theme/color/index.scss";
@import "../../../../theme/fonts/index.scss";

.choose_Section {
  min-height: 100vh;
  width: 100%;
  background-image: url("../../../../assets/images/main_bg.jpg");
  background-position: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: $fontFamily;
  .nested_ChooseSection {
    background: transparent $bgColorGradient no-repeat;
    opacity: 1;
    width: 100%;
    height: auto;
    min-height: 100vh;
    .container_ChooseSection {
      min-height: 100vh;
      width: 60%;
      margin: 0 auto;
      background-color: $cardColor;
      color: white;
      height: 100%;
      backdrop-filter: blur(4px);
      box-shadow: $boxshadowForInputs;
      // max-height: 100vh;
      .main_Heading {
        h1 {
          font-size: 64px;
          color: $headingColor;
          font-weight: $fontWeightForSubHeading;
        }
        p {
          font-size: $fontSizeForText;
          width: 60%;
          margin: 0 auto;
        }
        @media (max-width:768px) {
           h1{
            font-size: 44px;

           }
           p{
            width: 80%;
           }
        }
        @media (max-width:450px) {
          h1{
            font-size: 34px;
          }
        }
      }
      .select_Section {
        width: 80%;
        .box_1, .box_2 {
            width: 480px;
            height: 300px;
            // background: transparent linear-gradient(127deg, #9D020880 0%, #212227 100%) 0% 0% no-repeat;
            background-color:rgba(206, 128, 131, 0.2);
            box-shadow: inset 0px 0px 6px #E7E7E780, 0px 0px 6px #E7E7E780;
            border-radius: 20px;
            opacity: 1;
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px);
          h2{
            font-size: $fontSizeForHeading;
            font-weight: $fontWeightForText;
            text-align: center;
          }
          &:hover{
            background: transparent
            linear-gradient(127deg, #9d020880 0%, #212227 100%) 0% 0% no-repeat;
            cursor: pointer;
          } 
          @media (max-width:768px) {
            width: 300px;
            height: 235px;
            h2{
              font-size: $fontSizeForSubHeading;
            }
          } 
          @media (max-width:450px) {
            height: auto;
            width: 230px;
          }
        }
        @media (max-width:640px) {
          flex-direction: column;
        }
      }
      @media (max-width:1025px) {
        width: 80%;
      }
    @media (max-width:520px) {
      width: 90%;
    }
    }
  }
}


.circleImg{
  // width: 230px;
  // height: 160px;
  // // transform: matrix(0, 1, -1, 0, 0, 0);
  // /* UI Properties */
  // background: transparent url('../../../../assets/images/auth/Ellipse\ 18\ \(1\).svg') 0% 0% no-repeat;
  // mix-blend-mode: color-dodge;
  // opacity: 0.5;
  // position: relative;
  // img{
  //   position: absolute;
  //   top: 20%;
  //   left: 25%;
    
  // }
}
