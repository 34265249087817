@import "../../../../theme/color/index.scss";
@import "../../../../theme/fonts/index.scss";

.price_Section {
  background-color: $outlineWholePageSahdow;
  color: $headingColor;
  font-family: $fontFamily;

  .nested_PriceSection {
    width: 90%;
    margin: 0 auto;
    .heading_PriceSection {
      h1 {
        font-size: 54px;
        font-weight: $fontWeightForSubHeading;
        font-family: $fontFamily;
        text-align: center;
      }
      p {
        text-align: center;
        font-size: $fontSizeForSubHeading;
        font-weight: $fontWeightForText;
        letter-spacing: 0.2px;
        &:nth-child(3) {
          font-weight: $fontWeightForSubHeading;
          letter-spacing: 0.4px;
        }
      }
    }

   
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transform-style: preserve-3d;
    transform-origin: center right;
    transition: transform 1s;
    .table_PriceSection, .table_yearly {
      width: 90%;
      background: transparent
          linear-gradient(179deg, $themeColor 0%, #212227 100%) 0% 0%
          no-repeat;
      box-shadow: inset 0px 0px 10px #e7e7e780, 0px 0px 6px #e7e7e780;
      border-radius: 8px;
      opacity: 1;
      backdrop-filter: blur(9px);
      -webkit-backdrop-filter: blur(9px);
      padding: 20px;
      .chakra-table__container::-webkit-scrollbar {
        width: 10px;
      }
      .chakra-table__container::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 8px;
      }
      ::-webkit-scrollbar-thumb {
        background: transparent
          linear-gradient(179deg, $themeColor 10%, #212227 100%) 0% 0%
          no-repeat;
        border-radius: 8px;
      }
      thead {
        th {
          text-align: left;
          font-size: $fontSizeForHeading;
          color: $headingColor;
          font-weight: $fontWeightForText;
          letter-spacing: 0.2px;
          span{
            font-size: $fontSizeForText;
            font-weight: $fontWeightForText;
          }
        }
      }
      tbody{
        background: transparent
        linear-gradient(179deg, $themeColor 0%, #212227 100%) 0% 0%
        no-repeat;
        .price_section{
            td{
                font-size: $fontSizeForSubHeading;
                font-weight: $fontWeightForSubHeading;
            }
        }
        tr{
            td{
                padding: 14px 8px;
                font-size: $fontSizeForText;
                font-weight: $fontWeightForText;
             
            }
        }
      }

      @media (max-width:768px) {
        width: 100%;
      }
    }

    @media (max-width:1024px) {
      .heading_PriceSection{
        h1{
          font-size: 44px;      

        }
        p{
          font-size: $fontSizeForText;
        }
      }

    }
    @media (max-width:425px) {
      .heading_PriceSection{
        h1{
          font-size: $fontSizeForHeading;      

        }
      
      }

    }
  }
}
