@import "../../../../theme/color/index.scss";
@import "../../../../theme/fonts/index.scss";
.landing_Page {
  min-height: 100vh;
  .nested_LandingPage {
    background-image: url("../../../../assets/images/main_bg.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    // position: absolute;
    // height: 100vh;
    // overflow-y: hidden;
    height: auto;
    min-height: 100vh;

    .sub_NestedPage {
      background-image: url("../../../../assets/images/bgColorShade.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      // height: 100vh;
      font-family: $fontFamily;
      // overflow-y: hidden;
      height: auto;
      min-height: 100vh;


      .nested_Landing_Content {
        min-height: 100vh;
        // height: 100vh;
        padding: 180px 0px 60px;
        h1 {
          font-size: 100px;
          text-align: center;
          font-family: $fontFamily;
          font-weight: $fontWeightForSubHeading;
          color: $headingColor;
        }
        p {
          font-family: $fontFamily;
          font-size: $fontSizeForSubHeading;
          text-align: center;
          color: $headingColor;
        }

        .btn_landingPage_top {
          button {
            width: 100%;
            height: 64px;
            background-color: $headingColor;
            color: $landingPageColor;
            font-size: $fontSizeForSubHeading;
            box-shadow: $boxShadowBtn;
            transition: all 0.3s ease;
            cursor: pointer;
          }
          button:active {
            transform: scale(0.95);
          }
        }
        @media (max-width:1024px) {
          h1{
            font-size: 68px;
          }
        }
        @media (max-width:768px) {
          h1{
            font-size: 54px;
          }
        }
        @media (max-width:425px) {
          h1{
            font-size: 44px;
          }
        }
        @media (max-width:375px) {
          h1{
            font-size: $fontSizeForHeading;
          }
          p{
            font-size: $fontSizeForSubHeading;
          }
          .btn_landingPage_top{
            button{
              width: 200px;
              font-size: $fontSizeForText;
              img{
               display: none; 
              }
            }
          }
        }
      }
    }
  }
}
