@import "../../../../../theme/color/index.scss";
@import "../../../../../theme/fonts/index.scss";

.library_creator{
    h2,
    h1 {
      font-size: $fontSizeForHeading;
      font-family: $fontFamily;
      font-weight: $fontWeightForSubHeading;
    }
    p {
      font-size: $fontSizeForText;
      font-family: $fontFamily;
      font-weight: $fontWeightForText;
         
    }
    .main_card_library{
      width: 400px;
      border-radius: 4px;
    }
    .btn_section_library{
       button{
        font-family: $fontFamily;
        font-size: $fontSizeForText;
        font-weight: $fontWeightForText;
        text-decoration: underline;
       }  
    }
}