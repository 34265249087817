@import "../../../../../theme/color/index.scss";
@import "../../../../../theme/fonts/index.scss";
.account_Section_Creator {
  
  h2,
  h1 {
    font-size: $fontSizeForHeading;
    font-family: $fontFamily;
    font-weight: $fontWeightForSubHeading;
  }
  p {
    font-size: $fontSizeForText;
    font-family: $fontFamily;
    font-weight: $fontWeightForText;
    width: 60%;
    margin: 0 auto;
  }
  &:nth-child(4) {
    font-size: 22px;
  }
  .custom_scroll{
    overflow-y: auto;
    @media (max-height:700px) {
    height: 750px;
      
    }
  }
  .main_card {
    background: transparent linear-gradient(90deg, #9d0208 0%, #212227 100%);
    box-shadow: inset 0px 0px 20px #e7e7e7cc, 10px 10px 6px #e7e7e780;
    border-radius: 10px;
    opacity: 1;
    backdrop-filter: blur(9px);
    -webkit-backdrop-filter: blur(9px);
    // height: 100vh;
    .nested_mainCard {
      h4 {
        font-size: $fontSizeForSubHeading;
        font-family: $fontFamily;
        font-weight: $fontWeightForSubHeading;
        border-bottom: 2px solid #e7e7e780;
      }
      .edit_section {
        .inpout_section_edit{
          height: 350px;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width:8px;
            border-radius: 6px;
            
          }
          &::-webkit-scrollbar-track {
            background: #E7E7E7; 
            border-radius: 6px;

          }
          &::-webkit-scrollbar-thumb {
            background: #000; 
            border-radius: 6px;

          }
          &::-webkit-scrollbar-thumb:hover {
            background: #555; 
          }
        }
        
        label {
          padding: 10px 8px;
          display: block;
          text-align: left;
          font-family: $fontFamily;
          letter-spacing: 0px;
          color: #efefef;
          opacity: 1;
        }
        input, .content_section {
          width: 40%;
          padding: 10px;
          border-radius: 10px;
          opacity: 1;
          backdrop-filter: blur(7px);
          -webkit-backdrop-filter: blur(7px);
          background-color: transparent;
          box-shadow: inset 0px 0px 10px #9d02080d, 0px 0px 10px #eaeaeacc;
          margin: 4px;
          font-size: $fontSizeForText;
          font-family: $fontFamily;
          font-weight: $fontWeightForText;
          &:focus {
            outline: none;
            border: none;
          }
        }
        input[type="number"] {
          -moz-appearance: textfield;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        .edit_btn {
          // background: transparent url("../../../../../assets/images/buttonBox.svg") 0% 0% no-repeat;
          box-shadow: inset 0px 0px 20px #e7e7e7cc;
          border-radius: 6px;
          opacity: 1;
          backdrop-filter: blur(9px);
          -webkit-backdrop-filter: blur(9px);
          padding: 20px 0px;
          // margin-left: 10px;
          button {
            width: 240px;
            // background: var(--heading) 0% 0% no-repeat padding-box;
            background: #fbfbfb 0% 0% no-repeat padding-box;
            box-shadow: inset 0px 4px 5px #21222780;
            color: #000;
            opacity: 1;
            margin-left: 32px;
            font-size: $fontSizeForText;
            font-family: $fontFamily;
            
          }
          img{
            width: 28px;
          }
        }
      }
    }
  }
  // @media (max-width:1024px) {
  //     h1{
  //     font-size: $fontSizeForHeading;
  //     font-family: $fontFamily;
  //     font-weight: $fontWeightForSubHeading;
  //     }
  // }
}
