@import "../../../../../theme/color/index.scss";
@import "../../../../../theme/fonts/index.scss";
.resetpass_Section{
    min-height: 100vh;
    width: 100%;
    background-image: url("../../../../../assets/images/main_bg.jpg");
    background-position: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: $fontFamily;
.nested_resetpass{
    background: transparent $bgColorGradient no-repeat;
    opacity: 1;
    width: 100%;
    height: auto;
    min-height: 100vh;
    .container_forgPassSection{
      
        min-height: 100vh;
        width: 60%;
        margin: 0 auto;
        background-color: $cardColor;
        color: white;
        height: 100%;
        backdrop-filter: blur(4px);
        box-shadow: $boxshadowForInputs;
        .main_Heading{
            h1 {
                font-size: 64px;
                color: $headingColor;
                font-weight: $fontWeightForText;
              }
              @media (max-width:768px) {
                 h1{
                  font-size: 44px;
      
                 }
              }
              @media (max-width:450px) {
                h1{
                  font-size: 34px;
                }
              }
        }
        .forgetSection{
          
            input {
                width: 500px;
                margin: 10px 0px;
                padding: 14px 16px;
                // box-shadow: $boxshadowForInputs;
                border-radius: 10px;
                opacity: 1;
                backdrop-filter: blur(7px);
                -webkit-backdrop-filter: blur(7px);
                background-color: transparent;
                letter-spacing: 0.4px;
                font-size: 18px;
                &:focus {
                  border: none;
                  outline: none;
                }
                &::placeholder {
                  color: #eaeaea;
                }

              }
              .confirmed{
                input{
                margin-bottom: 0px;
            }
              }
            
              button {
                width: 100%;
                background-color: $themeColor;
                // border: 1px solid $themeColor;
                box-shadow: inset 0px 4px 5px #21222780;
                opacity: 1;
                // width: 40%;
                height: 58px;
                font-size: $fontSizeForSubHeading;
                @media (max-width:400px) {
                  width: 80%; 
                }
              }
        }
        @media (max-width:1025px) {
            width: 80%;
          }
        @media (max-width:520px) {
          width: 90%;
        }
        
    }
}

}