@import "../../../theme/color/index.scss";
@import "../../../theme/fonts/index.scss";

.footer_creator {
}
.footer_table {
  position: fixed;
  bottom: 0px;
  left: 0;
  z-index: 999;
  //   background-color: $themeColor;
  background: #3A1E20 0% 0% no-repeat;
  // opacity: 0.2;
  // backdrop-filter: ;

  width: 100%;
  padding-left: 300px;
  height: 60px;
  .fotter_links_creator {
    display: flex;
    // justify-content: flex-start;
    align-items: center;
    height: 100%;
    ul {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 14px;
      li {
        a {
          letter-spacing: 0px;
          color: #efefef;
          text-transform: capitalize;
          opacity: 1 !important;
        }
      }
    }
  }
}
