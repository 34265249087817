@import "../../../../../theme/color/index.scss";
@import "../../../../../theme/fonts/index.scss";

.creator_Details {
  width: 100%;
  min-height: 100vh;
  background-color: $outlineWholePageSahdow;
  max-height: auto;
  .nested_creatorDetails {
    position: relative;
    height: 100%;
    .steppers {
      font-family: $fontFamily;
      width: 80%;
      margin: 12px auto;
      background: transparent
      linear-gradient(90deg, $themeColor 0%, #212227 100%) 0% 0%
      no-repeat;
    background: transparent linear-gradient(90deg, #9d0208 0%, #212227 100%)
      0% 0% no-repeat;
    // border-radius: 32px
    height: 64px;
      div {
        height: 64px;
        width: 100%;
        opacity: 0.5;
        p {
          font-size: $fontSizeForText;
          font-weight: $fontWeightForText;
          color: $linkColor;
          letter-spacing: 0.3px;
          @media (max-width:768px) {
             display: none; 
          }
        }
        @media (max-width:425px) {
          height: 50px;
          img{
            width: 26px;
            height: 26px;
          }
        }
      }
      .stepper_Active {
        border-radius: 0px 100px 100px 0px !important;
        // background: transparent $themeColor 0% 0% no-repeat;
        background: $themeColor !important;
        opacity: 1;
      }
      .stepper_Completed {
        background: $themeColor !important;
        border-radius: 0px !important;
        opacity: 1;
      }
     @media (max-width:1024px) {
      width: 90%;
     }
      @media (max-width:800px) {
        width:  98%;
      }
      @media (max-width:425px) {
        height: 50px;
      }
    }
  }
}
