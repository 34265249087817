@import "../../../../theme/color/index.scss";
@import "../../../../theme/fonts/index.scss";

.partner_Section {
  background-color: $outlineWholePageSahdow;
  font-family: $fontFamily;
  .nested_PartnerSection {
    width: 90%;
    margin: 0 auto;
    div {
      h1 {
        font-size: 54px;
        font-weight: $fontWeightForSubHeading;
        font-family: $fontFamily;
        text-align: center;
        color: $headingColor;
      }
      p {
        text-align: center;
        font-size: $fontSizeForSubHeading;
        font-weight: $fontWeightForText;
        letter-spacing: 0.2px;
        color: $headingColor;
      }
    }
    .partner_Slider {
      .slick-slider {

        overflow: hidden;
        .slick-track {
          margin-bottom: 45px;
          .slick-slide {
            padding: 4px 8px !important;
          }
        }

        .slick-prev {
          width: 42px;
          height: 54px;
          position: absolute;
          background-image: url("../../../../assets/images/leftarrow.svg");
          background-repeat: no-repeat;
    background-position: center;
    background-size: 100%; 
          left: 20px;
          z-index: 99999;
          top: 30%;
          transform: translateY(-12%);
          cursor: pointer;
          &::before{
            visibility: hidden;
          }
          @media (max-width: 888px) {
            left: 0px;
          }
          @media (max-width: 560px) {
            left: 4px;
            z-index: 10;
          }
          @media (max-width: 360px) {
            left: 8px;
          }
        }
        .slick-next {
          width: 42px;
          height: 54px;
          position: absolute;
          background-image: url("../../../../assets/images/rightside.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100%; 
          right: 0px;
          top: 30%;
          transform: translateY(-12%);
          cursor: pointer;
          &::before{
            visibility: hidden;
          }
          @media (max-width: 888px) {
            right: 0px;
          }
          @media (max-width: 560px) {
            right: 18px;
            z-index: 10;
          }
          @media (max-width: 360px) {
            right: 8px;
          }
        }
        .slick-prev:before,
        .slick-next:before {
          // visibility: hidden;
          font-size: 28px !important;
        }

        // .slick-prev,
        // .slick-next {
        //   display: none !important;
        // }
        .slick-dots {
          // bottom: -1px !important;
          display: none !important;
          // z-index: 999 !important;
          li {
            width: 20px;
            height: 20px;
            background-color: $linkColor;
            border-radius: 50%;
            background: $linkColor 0% 0% no-repeat;

            button {
              &::before {
                //   color: #fff !important;
                opacity: 0 !important;
              }
            }
          }
          .slick-active {
            width: 20px;
            height: 20px;
            background: $themeColor 0% 0% no-repeat;
            border-radius: 50%;
            opacity: 1;
          }
        }
        img {
          margin: 0 auto;
        }
      }
    }
@media (max-width:768px) {
  div {
    h1 {
      font-size: 44px;
    }}
}
@media (max-width:480px) {
  width: 100%;
  div {
    h1 {
      font-size: 28px;
    }
    p{
      font-size: $fontSizeForText;
    }
  }
}
  }
}
