@import "../../../../theme/color/index.scss";
@import "../../../../theme/fonts/index.scss";

.threeFactor {
  width: 100%;
  background-color: $outlineWholePageSahdow;
  color: $headingColor;
  font-family: $fontFamily;
  .nested_threeFactor {
    width: 100%;
    .carosal_ThreeFactors {
      width: 100%;
      height: 100%;
      overflow-y: hidden;
      position: relative;
      //   margin-bottom: 50px;

      .slick-slider {
        position: initial;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        margin: 0 auto;
        overflow: hidden;

        .slick-track {
          margin-bottom: 45px;
          .slick-slide {
            padding: 4px 8px !important;
          }
        }


        .slick-prev {
          width: 52px;
          height: 54px;
          position: absolute;
          background-image: url("../../../../assets/images/leftarrow.svg");
          background-repeat: no-repeat;
    background-position: center;
    background-size: 100%; 
          left: 20px;
          z-index: 99;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          &::before{
            visibility: hidden;
          }
          @media (max-width:888px) {
            left: 16px;
         }
         @media (max-width:560px) {
          left: 4px;
          z-index: 10;
       }
       @media (max-width:360px) {
        left: 6px;
     }
        }
        .slick-next {
          width: 52px;
          height: 54px;
          position: absolute;
          background-image: url("../../../../assets/images/rightside.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100%; 

          right: 20px;
          top: 50%;
          z-index: 99;
          transform: translateY(-50%);
          cursor: pointer;
          &::before{
            visibility: hidden;
           
          }
          @media (max-width:888px) {
            right: 12px;
         }
         @media (max-width:560px) {
          right: 8px;
          z-index: 10;
       }
       @media (max-width:360px) {
        right: 8px;
     }
        }
        .slick-prev:before,
        .slick-next:before {
          // visibility: hidden;
          font-size: 28px !important;

        }











        // .slick-prev,
        // .slick-next {
        //   display: none !important;
        // }
        .slick-dots {
          bottom: -1px !important;
          // z-index: 999 !important;
          li {
            width: 20px;
            height: 20px;
            background-color: $linkColor;
            border-radius: 50%;
            background: $linkColor 0% 0% no-repeat;

            button {
              &::before {
                //   color: #fff !important;
                opacity: 0 !important;
              }
            }
          }
          .slick-active {
            width: 20px;
            height: 20px;
            background: $themeColor 0% 0% no-repeat;
            border-radius: 50%;
            opacity: 1;
          }
        }
      }

      .carosal_child {
        // width: 440px;
        // height: 100%;
        height: 610px !important;
        overflow-y: hidden;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        // background: transparent url("img/Rectangle 132.png") 0% 0% no-repeat;
        background: transparent
          linear-gradient(179deg, $themeColor 0%, #212227 100%) 0% 0% no-repeat;
        box-shadow: inset 0px 0px 10px #e7e7e780, 0px 0px 6px #e7e7e780;
        border-radius: 10px;
        opacity: 1;
        backdrop-filter: blur(9px);
        -webkit-backdrop-filter: blur(9px);
       
        h1 {
          font-size: $fontBigHeading;
          font-weight: $fontWeightForSubHeading;
          font-family: $fontFamily;
          text-align: center;
        }
        p {
          font-size: $fontSizeForText;
          font-weight: $fontWeightForText;
          font-family: $fontFamily;
          text-align: center;
          letter-spacing: 0.2px;
        }
        @media (max-width:768px) {
          height: 580px !important;
          img{
              width: 170px;
          }
          h1{
            font-size: $fontSizeForHeading;
          }
          p{
            font-size: 16px;
          }
        }
      }
    }
  }
}
