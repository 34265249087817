@import "../../../../../theme/color/index.scss";
@import "../../../../../theme/fonts/index.scss";

.media_details_section {
  width: 100%;
  overflow-y: hidden;
  // padding: 32px;
  .heading_media_details {
    font-family: $fontFamily;
  }
  .medai_details_section_from {
    color: $linkColor;
    // width: 50%;
    .section_form {
      padding: 2px 32px;
    }
    .form_control {
      display: flex;
      flex-direction: column;
      font-family: $fontFamily;
      margin: 8px 2px;
      label {
        font-size: $fontSizeForSubHeading;
        padding-left: 8px;
      }
      .form_control_div,
      .addButtongenre {
        background: transparent
          linear-gradient(179deg, $cardColor 0%, $outlineWholePageSahdow 100%)
          0% 0% no-repeat padding-box;
        background: transparent
          linear-gradient(179deg, #9d0208 0%, #212227 100%) 0% 0% no-repeat
          padding-box;
        box-shadow: inset 0px 0px 10px #9d02080d, 0px 0px 10px #eaeaeacc;
        border-radius: 10px;
        opacity: 1;
        backdrop-filter: blur(7px);
        -webkit-backdrop-filter: blur(7px);
        padding: 10px;
        margin: 4px;
      }
      input,
      textarea,
      select {
        background-color: transparent;
        width: 100%;
        border: none;
        outline: none;
        font-size: $fontSizeForText;
        letter-spacing: 0.5px;
      }
      .selection_box option {
        background-color: #212227;
      }

      .error {
        padding-left: 8px;
      }
    }
    .upload_image {
      input {
        cursor: pointer;
      }
    }
    .next_section_btn {
      background: transparent linear-gradient(179deg, #9d0208 0%, #212227 100%)
        0% 0% no-repeat padding-box;
      // box-shadow: inset 0px 0px 10px #9d02080d, 0px 0px 10px #eaeaeacc;
      box-shadow: inset 0px 0px 20px #e7e7e7cc;

      border-radius: 4px;
      padding: 20px 32px;
      .next_btn_first_step {
        button {
          width: 220px;
          height: 64px;
          background-color: $headingColor;
          color: $graphBackground;
          font-size: $fontSizeForSubHeading;
          box-shadow: $boxShadowBtn;
          @media (max-width: 600px) {
            width: 140px;
            // height: 48px;
          }
        }
      }
    }
    // second Section stepper
    .stepper_media_details{
      .css-14sza3e-MuiStepLabel-root{
        padding: 0px !important;
      }
      .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active{
        color: white !important;
        z-index: 99 !important;

      }
      .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root{
        font-size: 2rem !important;
        color: #E7E7E7;
        cursor: pointer;
      }
      .css-117w1su-MuiStepIcon-text{
        fill: none !important;
      }
      .css-14yr603-MuiStepContent-root {
        margin-left: 16px !important;
        border-left-width: 2px !important;
      }
      .css-1pe7n21-MuiStepConnector-root {
        margin-left: 16px !important;
      }
      .css-8t49rw-MuiStepConnector-line {
        min-height: 120px !important;
         border-left-width: 2px !important; 
      }
      .css-1hv8oq8-MuiStepLabel-label{
        color: #E7E7E7;
        font-size: 1.2rem;
      }


    }
  }
}
