@import "../../../../../theme/color/index.scss";
@import "../../../../../theme/fonts/index.scss";
.dashboard_creator{
    text-align: center;

    h1{
       font-size: $fontBigHeading; 
       font-family: $fontFamily;
       font-weight: $fontWeightForSubHeading;
    }
    h2{
        font-size: $fontSizeForHeading; 
       font-family: $fontFamily;
       font-weight: $fontWeightForSubHeading;
    }
    p{
        font-size: $fontSizeForText; 
        font-family: $fontFamily;
        font-weight: $fontWeightForText;   
        width: 60%;
        margin: 0 auto;
    }
    :nth-child(4){
        font-size: 22px;
    }
}