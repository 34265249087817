@import "../../../theme/color/index.scss";
@import "../../../theme/fonts/index.scss";
.upload_section{
 .nested_upload_section{

    .heading {
        h2 {
          font-size: $fontSizeForHeading;
          font-family: $fontFamily;
          font-weight: $fontWeightForSubHeading;
        }
      }
      .mainUploading_section{
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
        gap: 12px;
        .nested_uploading_section{
          width: 100%;
          background: transparent
              linear-gradient(179deg, $themeColor 0%, #212227 100%) 0% 0%
              no-repeat;
          box-shadow: inset 0px 0px 10px #e7e7e780, 0px 0px 6px #e7e7e780;
          border-radius: 8px;
          opacity: 1;
          backdrop-filter: blur(9px);
          -webkit-backdrop-filter: blur(9px);
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          div{
            font-family: $fontFamily;
            h3{
              overflow-y: hidden;

            }

            
          }
          .upload_btn{
           margin: 0 auto;
            button{
              padding: 12px;
              font-size: 20px;
              background-color: $linkColor;
              box-shadow: $boxShadowBtn;
              color: $black;
              width: 220px;
              margin: 16px 0px;
            }
          }
        }
          .series_section{
            display: none;
          }
      }
    }
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #E7E7E7; 
      border-radius: 8px;
    }
     &::-webkit-scrollbar-thumb {
      background:  #b30000; 
      border-radius: 10px;
    }
    &:-webkit-scrollbar-thumb:hover {
      background: #b30000; 
    }    
}