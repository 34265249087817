@import "../../../../theme/color/index.scss";
@import "../../../../theme/fonts/index.scss";

.about_BlokFlic{
    width: 100%;
    background-color: $outlineWholePageSahdow;
   .nested_AboutBlokFlic{

      flex-wrap: wrap;

      .about_BFContent{
        width: 40%;
        h1{
            font-size: 54px;
            font-weight: $fontWeightForSubHeading;
            color: $headingColor;
            font-family: $fontFamily;
        }
        p{
            font-weight: $fontWeightForText;
            color: $headingColor;
            font-size: $fontSizeForText;
            font-family: $fontFamily;
        }
        @media (max-width:500px) {
          h1{
            font-size: 42px;
          }
        }
        @media (max-width:380px) {
          h1{
            font-size: $fontSizeForHeading;
          }
        }
      }
      .about_Img{
        width: 60%;
      }
    @media (max-width:1024px) {
      .about_BFContent, .about_Img{
        width: 50%;
      }
    }
    @media (max-width:988px) {
      .about_BFContent, .about_Img{
        width: 100%;
        padding: 30px 0px;
      }
    } 
    @media (max-width:425px) {
      padding: 0rem !important;
   }
   }
   
}