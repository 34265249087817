@import "../../../theme/color/index.scss";
@import "../../../theme/fonts/index.scss";

.footer_Section{
    background-color: $outlineWholePageSahdow;
    font-family: $fontFamily;
    padding: 10px 0px;
    .nested_FooterSection{
        width: 90%;
        margin: 0 auto;
        
        .sectionone_Footer{
            border-top: 2px solid $borderColor;
            border-bottom: 2px solid $borderColor;
            display: grid;
            // grid-template-columns: repeat(auto-fit,minmax(320px, 1fr));
            grid-template-columns:  1.5fr repeat(3,minmax(200px, 250px));
            align-items: center;
            gap: 38px;
            h1{
                font-size: 54px;
                font-weight: $fontWeightForSubHeading;
                color: $headingColor;
                font-family: $fontFamily;
                text-transform: uppercase;
                letter-spacing: 0.4px;
            }
            p{
                font-weight: $fontWeightForText;
                color: $headingColor;
                font-size: $fontSizeForText;
                font-family: $fontFamily;
            }
            .link_section{
                h2{

                font-size: $fontSizeForSubHeading;
                font-weight: 600;
                color: $headingColor;
                font-family: $fontFamily;
                letter-spacing: 0.4px;
                }
                a{
                    font-weight: $fontWeightForText;
                color: $headingColor;
                font-size: $fontSizeForText;
                font-family: $fontFamily;  
                padding: 2px 0px;
                }
            }
           @media (max-width:1300px) {
            grid-template-columns: repeat(auto-fit,minmax(300px , 1fr));
            padding-left: 0px;
            padding-right: 0px;
           } 

        }
        .footer_EndSection{
            a,p{
                font-weight: $fontWeightForText;
                color: $headingColor;
                font-size: 12px;
                font-family: $fontFamily;  
                text-decoration: underline;
           }
           p{
            text-decoration: none;
           }
           div{
            padding-top: 12px;
            
           }
           @media (max-width:1024px) {
            padding-left: 0px;
            padding-right: 0px;
            
           }
           @media (max-width:425px) {
             gap: 0.4rem;
            
           }
        }
    }
}