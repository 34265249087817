@import "../../../../theme/color/index.scss";
@import "../../../../theme/fonts/index.scss";

.uploadcontent_section {
  background-color: $outlineWholePageSahdow;
  color: $headingColor;
  font-family: $fontFamily;

  .nested_uploadcontent {
    width: 90%;
    margin: 0 auto;
    h1 {
      font-size: 54px;
      font-weight: $fontWeightForSubHeading;
      font-family: $fontFamily;
      text-align: center;
    }
    p {
      text-align: center;
      font-size: $fontSizeForSubHeading;
      font-weight: $fontWeightForText;
      letter-spacing: 0.2px;
    }
    .uploadcontent_ImageSection{
        overflow-y: hidden;
        
        :first-child{
            transform: scale(0.82);  
        }
        img{

            &:nth-child(2){
                transform: scale(1.04);
            }
        }
       :nth-child(3){
        transform: scale(0.82);  

       }  
    } 
    .shadow_Image{
    .shadow_btn{
        width: 100%;
        display: flex;
        justify-content: center;
        button{
            background-color: $linkColor;
            color: $outlineWholePageSahdow;
            width: 220px;
            letter-spacing: 0.4px;
            box-shadow: $boxShadowBtn;
        }
        @media (max-width:600px) {
          top: 3rem;
        }
        @media (max-width:400px) {
          top: 2rem;
        }
    }
    }
    @media (max-width:768px) {
      h1{
        font-size: 34px;
      }
    }
    @media (max-width:480px) {
       p{
        font-size: $fontSizeForText;
       }    
    }
    @media (max-width:420px) {
     h1{
      font-size: $fontSizeForSubHeading;
     }
   }
  }
}
