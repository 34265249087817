@import "../../../../theme/color/index.scss";
@import "../../../../App.scss";
@import "../../../../theme/fonts/index.scss";

.btn {
  border-radius: $borderRadius;
  // box-shadow: $boxShadow;
  width: 100%;
  text-align: center;
  padding: 8px 18px;
}

.btn[disabled] {
  opacity: 0.8 !important;
  cursor: not-allowed !important;
}
