@import "../../../../../../theme/color/index.scss";
@import "../../../../../../theme/fonts/index.scss";


.steptwo_Form{
    width: 80%;
    margin: 0 auto;
    font-family: $fontFamily;
    color: $linkColor;
    .box_section_two{
      display: flex;
    }
    .nested_Steptwo{
        div {
            width: 100%;
            h1 {
              font-size: $fontSizeForHeading;
              font-weight: $fontWeightForSubHeading;
              letter-spacing: 0.2px;
              font-family: $fontFamily;
              @media (max-width:425px) {
                font-size: $fontSizeForSubHeading;
              }
            }
      
            .form_Steptwo{

              width: 100%;
              label {
                font-size: $fontSizeForText;
              }
              
              input {
                padding: 4px 12px;
                margin: 4px;
                width: 480px;
                height: 48px;
                letter-spacing: 0.2px;
                font-size: $fontSizeForText;
      
                background: transparent
                  linear-gradient(179deg, $themeColor 0%, #212227 100%) 0% 0%
                  no-repeat;
                //   background: transparent
                //     linear-gradient(179deg, #9d0208 0%, #212227 100%) 0% 0% no-repeat;
                box-shadow: inset 0px 0px 10px #9d02080d, 0px 0px 10px #eaeaeacc;
                border-radius: 10px;
                opacity: 1;
                backdrop-filter: blur(7px);
                -webkit-backdrop-filter: blur(7px);
      
                &:focus {
                  outline: none;
                }
                @media (max-width:1024px) {
                  width: 375px;
                }
                @media (max-width:450px) {
                  width: 98%;
                }
              }
               
              button{
                width: 240px;
                color: $graphBackground;
                font-size: $fontSizeForSubHeading;
              }
              .from_child{
                button{
                    text-align: center;
                    width: 480px;
                    color: $fontColor !important;
                    background: transparent
                    linear-gradient(179deg, $themeColor 0%, #212227 100%) 0% 0%
                    no-repeat;
                    //   background: transparent
                    //     linear-gradient(179deg, #9d0208 0%, #212227 100%) 0% 0% no-repeat;
                    box-shadow: inset 0px 0px 10px #9d02080d, 0px 0px 10px #eaeaeacc;
                    border-radius: 10px;
                   
                  &:focus {
                    outline: none;
                  }   
                  @media (max-width:1024px) {
                    width: 375px;
                  }
                  @media (max-width:450px) {
                    width: 98%;
                  } 
                }

              }
             
          
            }
          }

          .steppers_line {
            // border-left: 3px solid rgba(157, 2, 8, 1);      
            p {
              margin-left: 54px;
            }
            .stepper_boxes {
              div {
                width: 36px;
                height: 36px;
                // background-color: rgba(157, 2, 8, 1);
                background: transparent
                linear-gradient(179deg, $themeColor 0%, #212227 100%) 0% 0%
                no-repeat;
                border-radius: 50%;
                opacity: 1;
              }
            }
            @media (max-width:1024px) {
              height: 52vh;
              // margin-top: 0px;
                // p{
                //   margin-left: 100px;
                // }
              .stepper_boxess{
                left: 55px;
              }
            }
          }
          @media (max-width:1024px) {
            .desc_steptwo{
              margin-top: 0px;
            }
          }
          @media (max-width:800px) {
            .desc_steptwo{
              display: none;
            }
          }
    }
    @media (max-width:1024px) {
      width: 90%;
    }
    @media (max-width:450px) {
      width: 100%;
    }
}