@import "../../../../../../theme/color/index.scss";
@import "../../../../../../theme/fonts/index.scss";

.step_Three {
  width: 80%;
  margin: 0 auto;
  font-family: $fontFamily;
  color: $linkColor;

  .nested_Stepthree {
    .child_stepthree {
      width: 100%;
      h1 {
        font-size: $fontSizeForHeading;
        font-weight: $fontWeightForSubHeading;
        letter-spacing: 0.2px;
        font-family: $fontFamily;
      }
      .documentation {
        h2 {
          font-size: $fontSizeForHeading;
          font-weight: $fontWeightForText;
          color: $headingColor;
        }
        p {
          color: $headingColor;
        }
      }
      .cond_btn {
        .check_Box {
          text-decoration: underline;
          .css-dnty2r {
            width: 20px !important;
            height: 20px !important;
            border-radius: 50% !important;
            border: 1px solid white !important;
          }
        }
        p{
            text-decoration: underline;
             font-size: $fontSizeForText;
             @media (max-width:768px) {
              font-size: 14px;
             }
            //  @media (max-width:425px) {
            //   font-size: 12px;
            //  }
        }
        @media (max-width:425px) {
          flex-direction: column;
          align-items: flex-start;
           gap: 20px; 
           .check_Box{
            padding-left: 0px;
           }
        }
      }

      .submti_Btn {
        width: 240px;
        color: $graphBackground;
        font-size: $fontSizeForSubHeading;
      }
    }
   .stepperchild_stepthree{
    width: 70%;
     margin-top: 50px; 
     div{
      .circle {
        width: 36px;
        height: 36px;
        // background-color: rgba(157, 2, 8, 1);
        background: transparent
        linear-gradient(179deg, $themeColor 0%, #212227 100%) 0% 0%
        no-repeat;
        border-radius: 50%;
        opacity: 1;
      }
      
     }
     @media (max-width:800px) {
      display: none;
     }
   }




  }
  @media (max-width:1024px) {
    width: 90%;
  }
  @media (max-width:425px) {
    width: 98%;
  }
}
