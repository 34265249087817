@import "../../../../../../theme/color/index.scss";
@import "../../../../../../theme/fonts/index.scss";
:root {
  --circle-size: clamp(1.5rem, 5vw, 3rem);
  --spacing: clamp(0.25rem, 2vw, 0.5rem);
}
.authorized_dashboard {
  width: 100%;
  .nested_authorized_dashboard {
    .heading {
      h2 {
        font-size: $fontSizeForHeading;
        font-family: $fontFamily;
        font-weight: $fontWeightForSubHeading;
      }
    }
    .main_box {
      background: transparent linear-gradient(90deg, #9d0208 0%, #212227 100%);
      box-shadow: inset 0px 0px 20px #e7e7e7cc, 10px 10px 6px #e7e7e780;
      border-radius: 10px;
      opacity: 1;
      backdrop-filter: blur(9px);
      -webkit-backdrop-filter: blur(9px);
      padding: 20px 0px;
    .nested_box{
      .css-n7tliy-MuiStep-root{
        display: inline-table;
      }
    }
    }

    .c-stepper__item {
      position: relative;
      display: flex;
      gap: 1rem;
      padding-bottom: 4rem;

      &::before {
        content: "";
        flex: 0 0 var(--circle-size);
        height: var(--circle-size);
        border-radius: 50%;
        background-color: lightgrey;
        z-index: 999;
      }

      &:not(:last-child) {
        &::after {
          content: "";
          position: absolute;
          left: 0;
          // top: calc(var(--circle-size) + var(--spacing));
          // bottom: var(--spacing);
          top: 0px;
          bottom: 0px;

          z-index: 1;
          transform: translateX(calc(var(--circle-size) / 2));
          width: 2px;
          background-color: #e0e0e0;
          // height: 100%;
        }
      }
    }

    .c-stepper__title {
      font-weight: bold;
      font-size: clamp(1rem, 4vw, 1.25rem);
      margin-bottom: clamp(0.85rem, 2vmax, 1rem);
    }

    .c-stepper__desc {
      color: grey;
      font-size: clamp(0.85rem, 2vmax, 1rem);
    }

    .c-stepper__content {
      max-width: 700px;
    }

    /*** Non-demo CSS ***/

    .wrapper {
      max-width: 1000px;
      margin: 2rem auto 0;
    }

    //   body {
    //     font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    //       Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    //     padding: 1rem;
    //   }

    //   *,
    //   *:before,
    //   *:after {
    //     box-sizing: border-box;
    //   }
  }
}
