@import "../../../theme/color/index.scss";
@import "../../../theme/fonts/index.scss";
.main_Section_modal{
   height: 500px;
   position: relative;
   &::-webkit-scrollbar {
    width: 0px;
  }
  .cross_cut{
     position: absolute;
     top: 0px;
     right: 20px;
     font-size: $fontSizeForHeading;
     cursor: pointer;
  }
  
}
.done_btn{
    position: fixed;
    bottom: 4%;
    left: 0%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    button{
        width: 90%;
        height: 64px;
        background-color: $themeColor;
        font-size: $fontSizeForText;
        box-shadow: $boxShadowBtn;
    }
}