@import "../../../../../../theme/color/index.scss";
@import "../../../../../../theme/fonts/index.scss";

.stepone_FormSection {
  width: 80%;
  margin: 0 auto;
  font-family: $fontFamily;
  color: $linkColor;
  .nested_Stepone {
    div {
      width: 100%;
      h1 {
        font-size: $fontSizeForHeading;
        font-weight: $fontWeightForSubHeading;
        letter-spacing: 0.2px;
        font-family: $fontFamily;
        @media (max-width:425px) {
          font-size: $fontSizeForSubHeading;
        }
      }

      .form_StepOne {
        width: 100%;
        .first_section{
          display: flex;
        }
        // display: flex;
        label {
          font-size: $fontSizeForText;
        }
        input {
          padding: 4px 12px;
          margin: 2px;
          width: 480px;
          height: 48px;
          letter-spacing: 0.2px;
          font-size: $fontSizeForText;

          background: transparent
            linear-gradient(179deg, $themeColor 0%, #212227 100%) 0% 0%
            no-repeat;
          //   background: transparent
          //     linear-gradient(179deg, #9d0208 0%, #212227 100%) 0% 0% no-repeat;
          box-shadow: inset 0px 0px 10px #9d02080d, 0px 0px 10px #eaeaeacc;
          border-radius: 10px;
          opacity: 1;
          backdrop-filter: blur(7px);
          -webkit-backdrop-filter: blur(7px);

          &:focus {
            outline: none;
          }
          @media (max-width:1024px) {
            width: 375px;
          }
          @media (max-width:450px) {
            width: 98%;
          }
        }
        .child_Two {
          width: 480px;
          input {
            width: 98%;
          }
          @media (max-width:1024px) {
            // flex-direction: column;
            width: 375px;
          }
          @media (max-width:450px) {
            flex-direction: column;
            width: 98%;

          }
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          //   margin: 0;
        }

        /* Firefox */
        input[type="number"] {
          -moz-appearance: textfield;
        }

        button {
          width: 240px;
          color: $graphBackground;
          font-size: $fontSizeForSubHeading;
        }
      }
    }
    .steppers_lines {
      // border-left: 3px solid rgba(157, 2, 8, 1);      
      p {
        margin-left: 54px;
      }
      .stepper_boxess {
        div {
          width: 36px;
          height: 36px;
          // background-color: rgba(157, 2, 8, 1);
          background: transparent
          linear-gradient(179deg, $themeColor 0%, #212227 100%) 0% 0%
          no-repeat;
          border-radius: 50%;
          opacity: 1;
        }
      }

      @media (max-width:1024px) {
        // margin-top: 0px;
          p{
            margin-left: 100px;
          }
        .stepper_boxess{
          left: 55px;
        }
      }
    }
    @media (max-width:1024px) {
      .desc_steoOne{
        margin-top: 0px;
      }
    }
    @media (max-width:800px) {
      .desc_steoOne{
        display: none;
      }
    }
  }
  @media (max-width:1024px) {
    width: 90%;
  }
  @media (max-width:450px) {
    width: 100%;
  }
}
