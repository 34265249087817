@import "../../../../theme/color/index.scss";
@import "../../../../theme/fonts/index.scss";
:root {
  --circle-size: clamp(1.5rem, 5vw, 3rem);
  --spacing: clamp(0.25rem, 2vw, 0.5rem);
}

.media_upload_section {
  width: 100%;
  .nested_media_upload {
  }
}
.media_modal_action {
  z-index: 9999 !important;
}
.media_upload_modal {
  background: transparent linear-gradient(90deg, #9d0208 0%, #212227 100%);
  box-shadow: inset 0px 0px 20px #e7e7e7cc !important;

  border-radius: 10px;
  opacity: 1;
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
  padding: 20px 0px 0px;
  visibility: visible !important;
  opacity: 1 !important;
  overflow-y: auto !important;
  height: 90vh !important;
  // min-height: auto !important;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #e7e7e7;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #9d0208;
  }
}
.stepper_section {
  display: inline-table !important;
  .css-z7uhs0-MuiStepConnector-line {
    margin-top: 8px !important;
  }

  .stepper_child {
    .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
      font-size: 2.5rem !important;
    }
    .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root,
    .css-1hv8oq8-MuiStepLabel-label {
      color: rgba(255, 255, 255, 0.4);
    }
    .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active,
    .css-1hv8oq8-MuiStepLabel-label.Mui-active {
      color: white !important;
    }
    .css-1hv8oq8-MuiStepLabel-label.Mui-active,
    .css-1hv8oq8-MuiStepLabel-label {
      font-size: 1.2rem !important;
      font-family: $fontFamily;
    }

    .css-117w1su-MuiStepIcon-text {
      fill: black !important;
    }
  }
}
