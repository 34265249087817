@import "../../../../theme/color/index.scss";
@import "../../../../theme/fonts/index.scss";

.creator_dashboard{
    background-color: #212227;
     min-height: 100vh;
     max-height: auto;
     .nested_creator_dashboard{
        width: 90%;
        h1{
            color: $HeadingColor;
            font-size: $fontSizeForHeading;
            font-family: $fontFamily;
        }
        .table_section{
            .button_link{
               span{
                background-color: lightgrey;
                padding: 12px;
                border-radius: 4px;
               }
            }
        }

     }
}