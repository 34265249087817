@import "../../../../theme/color/";
@import "../../../../App.scss";

.ant-switch {
  // border-color: $borderColorLight !important;
  &:hover {
    border-color: $themeColor !important;
  }
}
.ant-switch-wrapper {
  color: $textColor !important;
  &:hover {
    border-color: $themeColor !important;
  }
}
.ant-switch-checked {
  border-color: $borderColorLight !important ;
  background: $themeColor !important;
  &:hover {
    border-color: $themeColor !important;
  }
}
.ant-switch-checked .ant-switch-inner:after {
  background-color: $themeColor !important;
  border-color: $themeColor !important;
}
.ant-switch:hover .ant-switch-inner {
  border-color: $themeColor !important;
}
