@import "../../../../theme/color/index.scss";
@import "../../../../theme/fonts/index.scss";
.main_admin_Header {
  // background-color: rgba(13, 13, 13, 0.4);
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  inset: 0;
  z-index: 999;
  background-color: $themeColor;
  .nested_admin_header {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    font-family: $fontFamily;
    color: $headingColor;

    .logo_Section {
      h1 {
        font-size: $fontSizeForHeading;
        font-weight: $fontWeightForText;
        color: $headingColor;
        font-family: $fontFamily;
        text-transform: uppercase;
      }
      a {
        text-decoration: underline;
       @media (max-width:520px) {
        display: none;
       } 
      }
    }
    .header_Buttons {
      
      p{
        font-size: $fontSizeForText;
        font-weight: $fontWeightForText;
        color: $headingColor;
        margin-right: 4px;
        margin-left: 34px;
    }
    svg{
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: cover;
    }
    }
    @media (max-width:350px) {
      width: 100%;
      padding: 12px 10px;
    }
  }
}
