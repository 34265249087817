@import "../../../../theme/color/index.scss";
@import "../../../../theme/fonts/index.scss";
.main_Login {
  min-height: 100vh;
  width: 100%;
  background-image: url("../../../../assets/images/main_bg.jpg");
  background-position: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: $fontFamily;
  .nested_Login {
    background: transparent $bgColorGradient no-repeat;
    //height: auto;
    opacity: 1;
    width: 100%;
    height: auto;
    min-height: 100vh;
    .container_Login {
      height: auto;
      min-height: 100vh;

      .form_section {
        min-height: 100vh;
        width: 400px;
        background-color: $cardColor;
        color: white;
        // height: 100%;
        margin-right: 20px;
        backdrop-filter: blur(3px);
        box-shadow: $boxshadowForInputs;

        .heading {
          font-size: $fontSizeForHeading;
          font-weight: $fontWeightForSubHeading;
        }
        .form_inputs {
          width: 100%;
          padding: 0px 22px;
          label {
            display: block;
            padding-left: 6px;
          }
          input {
            width: 100%;
            padding: 12px 16px;
            // box-shadow: $boxshadowForInputs;
            border-radius: 10px;
            opacity: 1;
            backdrop-filter: blur(7px);
            -webkit-backdrop-filter: blur(7px);
            background-color: transparent;
            letter-spacing: 0.4px;
            font-size: 18px;
            border: 1px solid white !important;

            &:focus {
              border: none;
              outline: none;
            }
            &::placeholder {
              color: #eaeaea;
            }
          }

          .check_Box {
            text-decoration: underline;
            .css-dnty2r {
              width: 20px !important;
              height: 20px !important;
              border-radius: 50% !important;
              border: 1px solid white !important;
            }
          }
          .login_Btn {
            width: 100%;
            button {
              background-color: $themeColor;
              // border: 1px solid $themeColor;
              box-shadow: inset 0px 4px 5px #21222780;
              opacity: 1;
              width: 240px;
              height: 58px;
              font-size: $fontSizeForSubHeading;
              img {
                width: 32px;
                height: 32px;
              }
            }
          }
          @media (max-width: 400px) {
            margin: 0 12px;
            input {
              width: 100%;
            }
          }
        }

        .btn_groups {
          width: 100%;

          button {
            background-color: $themeColor;
            // border: 1px solid $themeColor;
            box-shadow: inset 0px 4px 5px #21222780;
            opacity: 1;
            width: 40%;
            height: 58px;
            font-size: $fontSizeForSubHeading;
            @media (max-width: 400px) {
              width: 80%;
            }
          }
        }
        @media (max-width: 425px) {
          margin-right: 0px;
          width: 95%;
          .reminder_link {
            // label{
            font-size: 14px;
            // padding: 0px 12px;
            // }
          }
        }
      }

      .content_section {
        width: 60%;
        // background-color: red;
        height: 30%;
        color: white;

        .logo {
          font-size: $fontSizeForHeading;
          font-weight: $fontWeightForSubHeading;
        }
        .silder-Sectionlogin {
          width: 80%;
          p {
            font-size: $fontSizeForText;
          }
          .slick-slider {
            overflow-y: hidden;
            .slick-list {
              margin-bottom: 22px;
            }
            .slick-prev,
            .slick-next {
              display: none !important;
            }
            .slick-dots {
              bottom: 0px !important;
              li {
                width: 20px !important;
                height: 20px !important;
                background-color: $linkColor;
                border-radius: 50% !important;
                button {
                  &::before {
                    // color: #fff !important;
                    opacity: 0 !important;
                  }
                }
              }
              .slick-active {
                width: 20px !important;
                height: 20px !important;
                //    UI Properties
                // background: #ffffff 0% 0% no-repeat !important;
                background: $themeColor 0% 0% no-repeat;
                border-radius: 50% !important;
                opacity: 1 !important;
              }
            }
          }
        }
      }

      @media (max-width: 950px) {
        width: 100%;
        display: flex;
        justify-content: center;
        .content_section {
          display: none;
        }
      }
    }
  }
}
