@import "../../../theme/color/index.scss";
@import "../../../theme/fonts/index.scss";


.css-79ws1d-MuiModal-root, .newsletter-section{
   
    position: relative;
   font-family: $fontFamily;
}
.newsLtter_fading .close_btn{
    position: absolute;
    right:  20px;
    top: 20px;
    font-size: 24px;
    cursor: pointer;
    border: 2px solid $themeColor;
    border-radius: 50%;
    padding: 6px;
    svg{
        color: $themeColor;
    }
 }

.newsLtter_fading .css-1wnsr1i{
    width: 60%;
    border: none !important;
    outline: none !important;

    
}

.newsLtter_fading .css-l21kz9{
    border: none !important;
    outline: none !important;

    background-color: $headingColor;
    color: $graphBackground;
    font-size: $fontSizeForText;
      box-shadow: $boxShadowBtn;

}
// .mail_box{
//      position: absolute;
//      left: 45%;
//      top: 0px;

//      background-color: white;
//      z-index: 9999 !important;
//      svg{
//         font-size: 4rem;
//         z-index: 9999 !important;

//      }
//     }
.newsLtter_fading .heading, .newsLtter_fading .css-18h9l83{
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-weight: $fontWeightForSubHeading;
    img{
        width: 80px !important;
        height: 80px !important;
    }
}
.newsLtter_fading .desc, .css-9l3uo3{
    text-align: center !important;
}
.newsLtter_fading .sub_form{
   display: flex !important;
   gap: 20px !important;
   justify-content: center !important;
   align-items: center !important;
   margin: 20px 0px !important;
   input{
    border: 1px solid #808080;
    border-radius: 40px;
    padding: 14px;
    font-size: 18px;
    outline: none;
    width: 380px;
    &:focus{
        // border: none;
        // outline: none;
        border: 1px solid #808080;

    }

   }
   button, .newsLtter_fading .css-4xx2wk{
    border: none;
    border-radius: 40px;
    padding: 14px;
    font-size: 22px;
    width: 220px;
    background-color: $themeColor;
    font-family: $fontFamily;
   color: white;
   
   }
}
// @media (max-width:1024px) {
//     .css-l21kz9{
//         width: 80% !important;
//     }
// }

@media (max-width:1100px) {
    .css-l21kz9{
        width: 80% !important;
    }
}
@media (max-width:786px) {
    .css-l21kz9{
        width: 100% !important;
    }
}
@media (max-width:620px) {
    .css-l21kz9{
        width: 90% !important;
        padding: 32px 12px !important;
    }
     .newsLtter_fading .heading,  .css-18h9l83{
        display: flex !important;
        align-items: center;
        justify-content: center;
        font-weight: $fontWeightForSubHeading;
        font-size: 2rem !important;
        img{
            width: 60px !important;
            height: 60px !important;
        }
    }
    .newsLtter_fading .sub_form{
        flex-direction: column;
        div{
            width: 100%;
        }
        input, button{
          width: 100% !important;
        }
    }
}
@media (max-width:460px) {
    .newsLtter_fading .heading,  .css-18h9l83{
        width: 100% !important;
        font-size: 1.5rem !important;
        img{
            width: 45px !important;
            height: 45px !important;
        }
    }
    
}
@media (max-width:360px) {
    .newsLtter_fading .heading,  .css-18h9l83{
        font-size: 1.2rem !important;
        
    }
    
}
