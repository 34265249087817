@import "../../../../../theme/color/index.scss";
@import "../../../../../theme/fonts/index.scss";

.confirmation_fields{
    width: 100%;
    .nested_confirmation_field{
        width: 90%;
        margin: 0 auto;
        .heading_section{
            h1{
              color: $HeadingColor;
              font-size: $fontSizeForHeading;
            }
            p{
              color: $HeadingColor;
                 font-size: $fontSizeForText;
                 width: 520px;
            }
        }
        .confrimation_form{
            width: 100%;
            .form_details{
                width: 100%;
                color: white;
                .label_section{
                    font-size: $fontSizeForText;    
                    padding-left: 8px;
                }
                // p{
                //   font-size: $fontSizeForText;    
                // }

              .main_content{
                background: transparent
                linear-gradient(179deg, $cardColor 0%, $outlineWholePageSahdow 100%)
                0% 0% no-repeat padding-box;
              background: transparent
                linear-gradient(179deg, #9d0208 0%, #212227 100%) 0% 0% no-repeat
                padding-box;
              box-shadow: inset 0px 0px 10px #9d02080d, 0px 0px 10px #eaeaeacc;
              border-radius: 10px;
              opacity: 1;
              backdrop-filter: blur(7px);
              -webkit-backdrop-filter: blur(7px);
              padding: 10px 22px;
              margin: 4px;
              max-height: 200px;
              overflow-y: auto;
              p{
                font-size: 14px;
              }
              }  
              .main_content::-webkit-scrollbar {
                width: 10px;
              }
              .main_content::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px #E7E7E7; 
                border-radius: 8px;
              }
               
              /* Handle */
              .main_content::-webkit-scrollbar-thumb {
                background:  #212227; 
                border-radius: 10px;
              }
              
              /* Handle on hover */
              .main_content::-webkit-scrollbar-thumb:hover {
                background: #b30000; 
              }

            }
            .img_Section{
                width: 100%;
            }
        }
        
    }
    .submit_section_btn{
      background: transparent linear-gradient(179deg, #9d0208 0%, #212227 100%)
    0% 0% no-repeat padding-box;
  // box-shadow: inset 0px 0px 10px #9d02080d, 0px 0px 10px #eaeaeacc;
  box-shadow: inset 0px 0px 20px #e7e7e7cc;

  border-radius: 4px;
  padding: 20px 32px;
  .submit_btn{

  
      button {
        width: 220px;
        height: 64px;
        background-color: $headingColor;
        color: $graphBackground;
        font-size: $fontSizeForSubHeading;
        box-shadow: $boxShadowBtn;
        @media (max-width: 600px) {
          width: 140px;
          // height: 48px;
        }
      }
    }
    }
}