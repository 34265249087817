@import "../../../../theme/color/index.scss";
@import "../../../../theme/fonts/index.scss";
.nested_detail_page{
    // background-color: ;
    background-color: #212227;
    min-height: 100vh;
    max-height: auto;
    .detail_div{
        font-family: $fontFamily;

        width: 90%;
        margin: 90px auto;
        color: white;
        h2{
            font-family: $fontFamily;
        }
    }
}
.status-text {
    font-weight: bold;
}

.pending-color {
    background-color: orange;
}

.approved-color {
    background-color: #3DBE29;
}

.rejected-color {
    background-color: red;
}
.btn_status{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;

}
.btn_status button{   
    padding: 30px 0px;
    text-align: center;
    font-size: 18px;
    border: 1px solid black;
    border-radius: 8px;
}
.btn_status button:first-child{
    border: 2px solid #3DBE29;
    // background-color: rgba(61,190,41, 0.9);
}
.btn_status button:nth-child(2){
    border: 2px solid orange;
}
.btn_status button:nth-child(3){
    border: 2px solid red;
}
.text_area{
    border: 1px solid black;
    outline: none;
    border-radius: 8px;
    padding: 10px;
    letter-spacing: 0.3px;
}
.submit_btn_access{
    border: 2px solid #3DBE29 !important;
}